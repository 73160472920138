import { JSX }			from "preact";
import { useId }		from "preact/hooks";

import { classes }		from "ts-base/web/dom";

import { EditorEdit }	from "@geotoura/common/form/field";
import { FieldError }	from "@geotoura/common/form/FieldError";
import {
	TextSchema,
	TextModel,
	TextState,
}	from "@geotoura/common/form/textFieldModel";

export type TextFieldProps	= {
	type:			"text"|"email"|"tel"|"date",
	label:			string,
	placeholder:	string,
	missing:		string,
	invalid:		string,
	schema:			TextSchema,
	model:			TextModel,
	onEdit:			(edit:EditorEdit<TextState>)=>void,
};

export const TextField	= ({ type, label, placeholder, missing, invalid, schema, model, onEdit }:TextFieldProps):JSX.Element	=> {
	const uid	= useId();

	return (
		<div class={classes(
			"field",
			"field--text",
			schema.required			&& "field--required",
			schema.showError(model)	&& "field--invalid",
		)}>
			<label class="field-label" for={uid}>{label}</label>
			<div class="field-editor">
				<input
					id={uid}
					class="field-control"
					type={type}
					placeholder={placeholder}
					required={schema.required}
					value={model.value}
					onInput={ (ev) =>
						onEdit({ value: ev.currentTarget.value, touching: false })
					}
					onChange={ (ev) =>
						onEdit({ value: ev.currentTarget.value, touching: true })
					}
					autocomplete="off"
				/>
				{
					(schema.required || schema.restricted) &&
					<FieldError
						forId={uid}
						problem={schema.problem(model)}
						missing={missing}
						invalid={invalid}
					/>
				}
			</div>
		</div>
	);
};
