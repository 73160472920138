// TODO form has nothing to do with the model of a field in a form - this is about _presentation_ of fields!
export const scrollToError	= ():void	=> {
	const field	= document.querySelector(".field--invalid");
	if (field === null)	return;

	const control	= field.querySelector<HTMLElement>(".field-control");
	if (control !== null) {
		control.focus();
	}
	return;
};
