import { Fn }		from "ts-base/fn";
import { Either }	from "ts-base/either";

import {
	FieldSchema,
	FieldProblem,
	EditorSchema,
	EditorModel,
} from "@geotoura/common/form/field";

export type TextSchema	= EditorSchema<TextState>;

export type TextModel	= EditorModel<TextState>;

export type TextState	= string;

//-----------------------------------------------------------------------------
//## simple string

// all strings are valid
export const FreeText	= (initial:string):FieldSchema<TextState, string, string>	=>
	FieldSchema.unrestricted(initial);

//-----------------------------------------------------------------------------

export const OptionalText	= <T>(companion:Companion<T>) => (initial:T|null):FieldSchema<TextState, T|null, T|null>	=>
	FieldSchema.of({
		initial:	initial,
		required:	false,
		restricted:	true,
		parse:		(value) => value === "" ? Either.right(null) : FieldProblem.nullIsInvalid(companion.fromString(value)),
		unparse:	(initial) => initial !== null ? companion.toString(initial) : "",
	});

// "" is an invalid not-yet-set value
export const RequiredText	= <T>(companion:Companion<T>) => (initial:T|null):FieldSchema<TextState, T, T|null>	=>
	FieldSchema.of({
		initial:	initial,
		required:	true,
		restricted:	true,
		parse:		(value) => value === "" ? Either.left("missing") : FieldProblem.nullIsInvalid(companion.fromString(value)),
		unparse:	(initial) => initial !== null ? companion.toString(initial) : "",
	});

export type Companion<T>	= {
	fromString:	Fn<string, T|null>,
	toString:	Fn<T, string>,
};

//-----------------------------------------------------------------------------
//## example code

/*
const gregorianCompanion:Companion<time.Gregorian>	= {
	fromString:	time.Gregorian.parseEuropean,
	// TODO form fixed format is questionable
	toString:	time.Gregorian.isoString,
};

export const OptionalGregorianText	= OptionalText(gregorianCompanion);
export const RequiredGregorianText	= RequiredText(gregorianCompanion);
*/
