import { Maybe }	from "ts-base/maybe";
import { Arrays }	from "ts-base/arrays";

//-----------------------------------------------------------------------------

export type ChoiceList<T>	= {
	choices:	ReadonlyArray<Choice<T>>,
	// NOTE this is exclusively used to render feedback
	label:		(model:T) => string,
	key:		(model:T) => string,
	model:		(key:string) => T,
};

export const list	= <T>(choices:ReadonlyArray<Choice<T>>):ChoiceList<T>	=> ({
	choices:	choices,
	label:		(model) => byModel(choices, model).label,
	key:		(model) => byModel(choices, model).key,
	model:		(key)	=> byKey(choices, key).model,
});

const byKey	= <T>(choices:ReadonlyArray<Choice<T>>, key:string):Choice<T>	=>
	Maybe.unsafeGet(
		Arrays.find(choices)((it) => it.key === key)
	);

const byModel	= <T>(choices:ReadonlyArray<Choice<T>>, model:T):Choice<T>	=>
	Maybe.unsafeGet(
		Arrays.find(choices)((it) => it.model === model)
	);

//-----------------------------------------------------------------------------

export type Choice<T>	= Readonly<{
	model:	T,
	key:	string,
	label:	string,
}>;
