import { Branded }	from "ts-base/branded";

import { CountryId }	from "@geotoura/shared/primitive";

export type DestinationId	= Branded<number, "DestinationId">;
export const DestinationId	= Branded.brand<DestinationId>;

export type HotelId			= Branded<number, "HotelId">;
export const HotelId		= Branded.brand<HotelId>;

export type RegionId		= Branded<number, "RegionId">;
export const RegionId		= Branded.brand<RegionId>;

export type TerritoryId		= Branded<number, "TerritoryId">;
export const TerritoryId	= Branded.brand<TerritoryId>;

export type ExampleRouteId	= Branded<number, "ExampleRouteId">;
export const ExampleRouteId	= Branded.brand<ExampleRouteId>;

export type CarCategoryId	= Branded<number, "CarCategoryId">;
export const CarCategoryId	= Branded.brand<CarCategoryId>;

export type CarCategory = Readonly<{
	id:		CarCategoryId,
	name:	string,
}>;

export type HotelCategoryId		= Branded<number, "HotelCategoryId">;
export const HotelCategoryId	= Branded.brand<HotelCategoryId>;

export type HotelCategory = Readonly<{
	id:		HotelCategoryId,
	name:	string,
}>;

export type HotelSiteId		= Branded<number, "HotelSiteId">;
export const HotelSiteId	= Branded.brand<HotelSiteId>;

export type HotelSite = Readonly<{
	id:		HotelSiteId,
	name:	string,
}>;

export type IsoCountry	= Readonly<{
	code:	CountryId,
	name:	string,
}>;

export type RouteName = Readonly<{
	name:	string,
}>;

//-----------------------------------------------------------------------------

// we send lots of these in the up api, therefore the property names have to be short
export type Coordinate	= Readonly<{
	lat:	Latitude,
	lng:	Longitude,
}>;

export type Latitude	= Branded<number, "Latitude">;
export const Latitude	= Branded.brand<Latitude>;

export type Longitude	= Branded<number, "Longitude">;
export const Longitude	= Branded.brand<Longitude>;
