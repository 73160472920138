import { Branded }		from "ts-base/branded";
import { Validator }	from "ts-base/validation/validator";

// YYYY-MM-DD
export type IsoDate		= Branded<string, "IsoDate">;

export namespace IsoDate{
	export const brand	= Branded.brand<IsoDate>;

	export const fromDateUtc	= (date:Date):IsoDate	=>
		brand(date.toISOString().slice(0, 10));

	export const fromString	= (s:string):IsoDate|null	=>
		/^\d{4}-\d{2}-\d{2}$/.test(s) ? brand(s) : null;

	export const validateString:Validator<string, IsoDate>	=
		Validator.fromNullResult(fromString, "expected a valid iso date");
}
