import { Fn }			from "ts-base/fn";
import { Either }		from "ts-base/either";

import {
	FieldSchema,
	EditorSchema,
	EditorModel,
} from "@geotoura/common/form/field";

export type CheckboxSchema	= EditorSchema<CheckboxState>;

export type CheckboxModel	= EditorModel<CheckboxState>;

export type CheckboxState	= boolean;

// both checked an unchecked are valid
export const FreeCheckbox	= (initial:boolean):FieldSchema<CheckboxState, boolean, boolean>	=>
	FieldSchema.unrestricted(initial);

// unchecked is an invalid not-yet-set value
export const RequiredCheckbox	= (initial:boolean):FieldSchema<CheckboxState, null, boolean>	=>
	FieldSchema.of({
		initial:	initial,
		required:	true,
		restricted:	false,
		parse:		(value) => value ? Either.right(null)	: Either.left("missing"),
		unparse:	Fn.identity,
	});
