import { ComponentChild, JSX }	from "preact";
import { useId }				from "preact/hooks";

import { classes }		from "ts-base/web/dom";

import { EditorEdit }	from "@geotoura/common/form/field";
import { FieldError }	from "@geotoura/common/form/FieldError";
import {
	CheckboxSchema,
	CheckboxModel,
	CheckboxState,
}	from "@geotoura/common/form/checkboxFieldModel";

export type CheckboxFieldProps	= {
	label:		ComponentChild,
	missing:	string,
	invalid:	string,
	schema:		CheckboxSchema,
	model:		CheckboxModel,
	onEdit:		(value:EditorEdit<CheckboxState>)=>void,
};

export const CheckboxField	= ({ label, missing, invalid, schema, model, onEdit }:CheckboxFieldProps):JSX.Element	=> {
	const uid	= useId();

	return (
		<div class={classes(
			"field",
			"field--checkbox",
			schema.required			&& "field--required",
			schema.showError(model)	&& "field--invalid",
		)}>
			<input
				id={uid}
				class="field-control"
				type="checkbox"
				required={schema.required}
				checked={model.value}
				onChange={ (ev) =>
					onEdit({ value: ev.currentTarget.checked, touching: true })
				}
				autocomplete="off"
			/>
			{
				(schema.required || schema.restricted) &&
				<FieldError
					forId={uid}
					problem={schema.problem(model)}
					missing={missing}
					invalid={invalid}
				/>
			}
			<label class="field-label" for={uid}>
				{ label }
			</label>
		</div>
	);
};
