import { JSX }			from "preact";
import { useId }		from "preact/hooks";

import { classes }		from "ts-base/web/dom";

import { ChoiceList }	from "@geotoura/common/form/choice";
import { EditorEdit }	from "@geotoura/common/form/field";
import { FieldError }	from "@geotoura/common/form/FieldError";
import {
	RadioSchema,
	RadioModel,
	RadioState,
}	from "@geotoura/common/form/radioFieldModel";

export type RadioFieldProps<T>	= {
	label:		string,
	missing:	string,
	invalid:	string,
	choices:	ChoiceList<T>,
	schema:		RadioSchema<T>,
	model:		RadioModel<T>,
	onEdit:		(edit:EditorEdit<RadioState<T>>)=>void,
};

export const RadioField	= <T,>({ label, missing, invalid, choices, schema, model, onEdit }:RadioFieldProps<T>):JSX.Element	=> {
	const uid	= useId();

	return (
		<div class={classes(
			"field",
			"field--radio",
			schema.required			&& "field--required",
			schema.showError(model)	&& "field--invalid",
		)}>
			{/* TODO form this is not actually "for" anything... */}
			<label class="field-label" for={uid}>{label}</label>
			<div class="field-editor">
				{
					choices.choices.map((choice) => {
						// TODO form this does not exist in any other component
						return (
							<div class="field-item">
								<input
									id={uid + "-" + choice.key}
									class="field-control"
									type="radio"
									required={schema.required}
									value={choice.key}
									checked={model.value === choice.model}
									onChange={ () =>
										onEdit({ value: choice.model, touching: true })
									}
									autocomplete="off"
								/>
								<label class="field-label" for={uid + "-" + choice.key}>{choice.label}</label>
							</div>
						);
					})
				}
				{
					// TODO form this is not actually "for" anything...
					(schema.required || schema.restricted) &&
					<FieldError
						forId={uid}
						problem={schema.problem(model)}
						missing={missing}
						invalid={invalid}
					/>
				}
			</div>
		</div>
	);
};
