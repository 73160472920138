import * as commonModel	from "@geotoura/shared/commonModel";

import * as identifier	from "@geotoura/common/util/identifier";

export type PageInfo = FrontPageInfo | RoutePageInfo | RegionPageInfo | TerritoryPageInfo;

export type FrontPageInfo = {
	type: "front",
};

export type RoutePageInfo = {
	type:		"route",
	routeId:	commonModel.ExampleRouteId,
};

export type RegionPageInfo = {
	type:		"region",
	routeId:	commonModel.ExampleRouteId,
	regionId:	commonModel.RegionId,
};

export type TerritoryPageInfo = {
	type:			"territory",
	territoryId:	commonModel.TerritoryId,
};

export const get = ():PageInfo => {
	const routeId		= identifier.identifierOrNull(document.body.getAttribute("data-route"),		commonModel.ExampleRouteId);
	const regionId		= identifier.identifierOrNull(document.body.getAttribute("data-region"),	commonModel.RegionId);
	const territoryId	= identifier.identifierOrNull(document.body.getAttribute("data-territory"),	commonModel.TerritoryId);

	return (
		routeId !== null && regionId === null ?
			{
				type: "route",
				routeId,
			}
			:
		routeId !== null && regionId !== null ?
			{
				type: "region",
				routeId,
				regionId,
			}
			:
		territoryId !== null ?
			{
				type: "territory",
				territoryId,
			}
			:
		// else
			{
				type: "front",
			}
	);
};
