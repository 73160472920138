/* eslint-disable @typescript-eslint/no-explicit-any */

import { Fn }			from "ts-base/fn";
import { Endo }			from "ts-base/endo";
import { Maybe }		from "ts-base/maybe";
import { Either }		from "ts-base/either";
import { Nullable }		from "ts-base/nullable";

/**
S is the raw type stored in the model,
T is the result type for the user,
U is an init value
*/
export type FieldSpec<S, T, U>	= Readonly<{
	initial:	U,

	// NOTE this must be true if FieldProblem can include "missing"
	required:	boolean,
	// NOTE this must be true if FieldProblem can include "invalid"
	restricted:	boolean,

	parse:		Fn<S, Either<FieldProblem, T>>,
	unparse:	Fn<U, S>,
}>;

/**
S is the raw type stored in the model,
T is the result type for the user
U is an init value
*/
export type FieldSchema<S, T, U>	= Readonly<{
	initial:	() => EditorModel<S>,
	inject:		Fn<U, EditorModel<S>>,
	extract:	Fn<EditorModel<S>, Maybe<T>>,
	touch:		Endo<EditorModel<S>>,
	untouch:	Endo<EditorModel<S>>,

	// NOTE this must be true if FieldProblem can include "missing"
	required:	boolean,
	// NOTE this must be true if FieldProblem can include "invalid"
	restricted:	boolean,
	valid:		Fn<EditorModel<S>, boolean>,
	showError:	Fn<EditorModel<S>, boolean>,
	problem:	Fn<EditorModel<S>, Nullable<FieldProblem>>,
}>;

export namespace FieldSchema {
	export type ModelOf<F>	= EditorModel<RawOf<F>>;
	export type EditOf<F>	= EditorEdit<RawOf<F>>;

	export type RawOf<F>		= F extends FieldSchema<infer S, any, any> ? S	: never;
	export type ExtractOf<F>	= F extends FieldSchema<any, infer T, any> ? T	: never;
	export type InjectOf<F>		= F extends FieldSchema<any, any, infer U> ? U	: never;

	export const of	= <S, T, U>(spec:FieldSpec<S, T, U>):FieldSchema<S, T, U>	=> {
		const parseModel	= (model:EditorModel<S>):Either<FieldProblem, T>	=> spec.parse(model.value);

		const initial		= ():EditorModel<S>							=> EditorModel.init(spec.unparse(spec.initial));
		const inject		= (initial:U):EditorModel<S>				=> EditorModel.init(spec.unparse(initial));
		const extract		= (model:EditorModel<S>):Maybe<T>			=> Either.toMaybe(parseModel(model));

		const valid			= (model:EditorModel<S>):boolean			=> Either.isRight(parseModel(model));
		const showError		= (model:EditorModel<S>):boolean			=> model.touched && !valid(model);
		const problem		= (model:EditorModel<S>):FieldProblem|null	=> Either.toNullable(Either.swap(parseModel(model)));

		return {
			initial,
			extract,
			inject,
			touch:		EditorModel.touch,
			untouch:	EditorModel.untouch,

			required:	spec.required,
			restricted:	spec.restricted,
			valid,
			showError,
			problem,
		};
	};

	// the simple case: no validation, not required
	export const unrestricted	= <T>(initial:T):FieldSchema<T, T, T>	=>
		FieldSchema.of({
			initial:	initial,
			required:	false,
			restricted:	false,
			parse:		FieldProblem.noProblem,
			unparse:	Fn.identity,
		});
}

//-----------------------------------------------------------------------------

export type EditorModel<T>	= {
	value:		T,
	touched:	boolean,
};

export type EditorEdit<T>	= {
	value:		T,
	touching:	boolean,
};

export namespace EditorModel {
	// NOTE with FreeSelect, the initial value must not be None
	export const init	= <T>(value:T):EditorModel<T>	=> ({
		value:		value,
		touched:	false,
	});

	export const edit	= <T>(edit:EditorEdit<T>):Endo<EditorModel<T>>	=> orig => ({
		value:		edit.value,
		touched:	orig.touched || edit.touching,
	});

	export const touch	= <T>(orig:EditorModel<T>):EditorModel<T>	=> ({
		value:		orig.value,
		touched:	true,
	});

	export const untouch	= <T>(orig:EditorModel<T>):EditorModel<T>	=> ({
		value:		orig.value,
		touched:	false,
	});

	export const copy	= <T>(source:EditorModel<T>):EditorModel<T>	=>
		init(source.value);
}

//-----------------------------------------------------------------------------
//## editor interface

// all field editors have these
export type EditorProps<S>	= {
	schema:	EditorSchema<S>,
	model:	EditorModel<S>,
	onEdit:	Fn<EditorEdit<S>, void>,
};

export namespace EditorProps {
	export const of	= <S, T, U>(schema:FieldSchema<S, T, U>) => (model:EditorModel<S>, updateModel:Fn<Endo<EditorModel<S>>, void>):EditorProps<S>	=> ({
		schema:	EditorSchema.of(schema),
		model:	model,
		onEdit:	(value) => updateModel(EditorModel.edit(value)),
	});
}

export type EditorSchema<S>	= Readonly<{
	// NOTE this must be true if FieldProblem can include "missing"
	required:	boolean,

	// NOTE this must be true if FieldProblem can include "invalid"
	restricted:	boolean,

	touch:		Endo<EditorModel<S>>,
	showError:	Fn<EditorModel<S>, boolean>,
	problem:	Fn<EditorModel<S>, Nullable<FieldProblem>>,
}>;

export namespace EditorSchema {
	export const of	= <S, T, U>(schema:FieldSchema<S, T, U>):EditorSchema<S>	=>
		schema;
}

//-----------------------------------------------------------------------------
//## parser

export type FieldProblem	= "missing" | "invalid";

export namespace FieldProblem {
	export const noProblem		= <T>(value:T):Either<FieldProblem, T>	=>
		Either.right(value);

	export const nullIsInvalid	= <T>(value:Nullable<T>):Either<FieldProblem, T>	=>
		Either.fromNullable(value)("invalid");

	/*
	export const nullIsMissing	= <T>(value:Nullable<T>):Either<FieldProblem, T>	=>
		either.fromNullable(value)("missing");

	export const noneIsInvalid	= <T>(value:Maybe<T>):Either<FieldProblem, T>	=>
		either.fromMaybe(value)("invalid");
	*/

	export const noneIsMissing	= <T>(value:Maybe<T>):Either<FieldProblem, T>	=>
		Either.fromMaybe(value)("missing");
}
