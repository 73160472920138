/* eslint-disable @typescript-eslint/no-explicit-any */

import { ExactPartial }	from "ts-base/type";
import { Fn, Fn2 }		from "ts-base/fn";
import { Endo }			from "ts-base/endo";
import { Zoomer }		from "ts-base/zoomer";
import { Objects }		from "ts-base/objects";
import { Maybe }		from "ts-base/maybe";

import { FieldSchema, EditorProps }		from "@geotoura/common/form/field";

export type AnyFormSpec	= Record<string, FieldSchema<any, any, any>>;

export namespace FormSpec {
	export type ModelOf<F>			= { [K in keyof F]: FieldSchema.ModelOf<F[K]> };
	export type InjectOf<F>			= { [K in keyof F]: FieldSchema.InjectOf<F[K]> };
	export type ExtractOf<F>		= { [K in keyof F]: FieldSchema.ExtractOf<F[K]> };
	export type EditorPropsOf<F>	= { [K in keyof F]: EditorProps<FieldSchema.RawOf<F[K]>> };
}

//-----------------------------------------------------------------------------

export type FormSchema<F extends AnyFormSpec>	= {
	fields:				F,
	initial:			() => FormSpec.ModelOf<F>,
	inject:				Fn<FormSpec.InjectOf<F>, FormSpec.ModelOf<F>>,
	injectPartial:		Fn<ExactPartial<FormSpec.InjectOf<F>>, Fn<FormSpec.ModelOf<F>, FormSpec.ModelOf<F>>>,
	extract:			Fn<FormSpec.ModelOf<F>, Maybe<FormSpec.ExtractOf<F>>>,
	extractNullable:	Fn<FormSpec.ModelOf<F>, FormSpec.ExtractOf<F>|null>,
	touch:				Endo<FormSpec.ModelOf<F>>,
	untouch:			Endo<FormSpec.ModelOf<F>>,
	valid:				Fn<FormSpec.ModelOf<F>, boolean>,
	editorProps:		Fn2<
		FormSpec.ModelOf<F>,
		Fn<Endo<FormSpec.ModelOf<F>>, void>,
		FormSpec.EditorPropsOf<F>
	>,
};

export namespace FormSchema {
	export type ModelOf<F>			= F extends FormSchema<infer X> ? Readonly<FormSpec.ModelOf<X>>			: never;
	export type InjectOf<F>			= F extends FormSchema<infer X> ? Readonly<FormSpec.InjectOf<X>>		: never;
	export type ExtractOf<F>		= F extends FormSchema<infer X> ? Readonly<FormSpec.ExtractOf<X>>		: never;
	export type EditorPropsOf<F>	= F extends FormSchema<infer X> ? Readonly<FormSpec.EditorPropsOf<X>>	: never;

	export const of	= <F extends AnyFormSpec>(spec:F):FormSchema<F>	=> ({
		fields:				spec,
		initial:			() => init(spec),
		inject:				inject(spec),
		injectPartial:		injectPartial(spec),
		extract:			extract(spec),
		extractNullable:	Fn.andThen(extract(spec), Maybe.toNullable),
		touch:				touch(spec),
		untouch:			untouch(spec),
		valid:				valid(spec),
		editorProps:		editorProps(spec),
	});

	//-----------------------------------------------------------------------------

	const init	= <F extends AnyFormSpec>(spec:F):FormSpec.ModelOf<F>	=> {
		const out	= {} as any;
		for (const k in spec) {
			out[k]	= (spec[k] as FieldSchema<any, any, any>).initial();
		}
		return out;
	};

	const inject	= <F extends AnyFormSpec>(spec:F) => (inject:FormSpec.InjectOf<F>):FormSpec.ModelOf<F>	=> {
		const out	= {} as any;
		for (const k in spec) {
			const injected	= (spec[k] as FieldSchema<any, any, any>).inject(inject[k]);
			out[k]	= injected;
		}
		return out;
	};

	const injectPartial	= <F extends AnyFormSpec>(spec:F) => (inject:ExactPartial<FormSpec.InjectOf<F>>) => (model:FormSpec.ModelOf<F>):FormSpec.ModelOf<F>	=> {
		const out	= {} as any;
		for (const k in spec) {
			if (!Objects.hasOwn(inject, k))	continue;
			const injected	= (spec[k] as FieldSchema<any, any, any>).inject(inject[k]);
			out[k]	= injected;
		}
		return {
			...model,
			...out,
		};
	};

	const extract	= <F extends AnyFormSpec>(spec:F) => (model:FormSpec.ModelOf<F>):Maybe<FormSpec.ExtractOf<F>>	=> {
		const out	= {} as any;
		for (const k in spec) {
			const extracted	= (spec[k] as FieldSchema<any, any, any>).extract(model[k]);
			if (Maybe.isNone(extracted))	return Maybe.none();
			out[k]	= extracted.value;
		}
		return Maybe.some(out);
	};

	const touch	= <F extends AnyFormSpec>(spec:F) => (model:FormSpec.ModelOf<F>):FormSpec.ModelOf<F>	=> {
		const out	= {} as any;
		for (const k in spec) {
			out[k] = (spec[k] as FieldSchema<any, any, any>).touch(model[k]);
		}
		return out;
	};

	const untouch	= <F extends AnyFormSpec>(spec:F) => (model:FormSpec.ModelOf<F>):FormSpec.ModelOf<F>	=> {
		const out	= {} as any;
		for (const k in spec) {
			out[k] = (spec[k] as FieldSchema<any, any, any>).untouch(model[k]);
		}
		return out;
	};

	const valid	= <F extends AnyFormSpec>(spec:F) => (model:FormSpec.ModelOf<F>):boolean	=> {
		for (const k in spec) {
			const ok	= (spec[k] as FieldSchema<any, any, any>).valid(model[k]);
			if (!ok)	return false;
		}
		return true;
	};

	//-----------------------------------------------------------------------------

	const editorProps	= <F extends AnyFormSpec>(spec:F) => (formModel:FormSpec.ModelOf<F>, updateFormModel:Fn<Endo<FormSpec.ModelOf<F>>, void>):FormSpec.EditorPropsOf<F>	=> {
		const out	= {} as FormSpec.EditorPropsOf<F>;
		for (const k in spec) {
			out[k]	= fieldEditorProps(spec, k)(formModel, updateFormModel);
		}
		return out;
	};

	const fieldEditorProps	= <F extends AnyFormSpec, K extends keyof F>(spec:F, key:K) => (formModel:FormSpec.ModelOf<F>, updateFormModel:Fn<Endo<FormSpec.ModelOf<F>>, void>):FormSpec.EditorPropsOf<F>[K]	=> {
		type FormModel		= FormSpec.ModelOf<F>;
		type FieldSchema	= F[K];
		type FieldModel		= FieldSchema.ModelOf<FieldSchema>;

		const fieldSchema:FieldSchema						= spec[key];
		const fieldModel:FieldModel							= formModel[key];
		const updateFieldModel:Fn<Endo<FieldModel>, void>	= endo => updateFormModel(Zoomer.on<FormModel>().atKey(key).mod(endo));

		return EditorProps.of(fieldSchema)(fieldModel, updateFieldModel);
	};
}
