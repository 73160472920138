import { JSX }	from "preact";

import { Endo }				from "ts-base/endo";

import { useLanguageCode }	from "@geotoura/common/util/useLanguageCode";
import { AreaField }		from "@geotoura/common/form/AreaField";
import { CheckboxField }	from "@geotoura/common/form/CheckboxField";
import { RadioField }		from "@geotoura/common/form/RadioField";
import { TextField }		from "@geotoura/common/form/TextField";

import {
	contactWays,
	MeetingGeneralModel,
	MeetingGeneralSchema,
	MeetingPhoneModel,
	MeetingPhoneSchema,
	FormState,
} from "@geotoura/floater/model";
import { useMessages }		from "@geotoura/floater/useMessages";
import * as actions			from "@geotoura/floater/actions";

import { Error }		from "@geotoura/floater/Error";
import { Success }		from "@geotoura/floater/Success";
import { Headline }		from "@geotoura/floater/Headline";

export type MeetingProps	= Readonly<{
	data: {
		general:	MeetingGeneralModel,
		phone:		MeetingPhoneModel,
	},
	state:	FormState,
	modifyGeneral:	(change:Endo<MeetingGeneralModel>) => void,
	modifyPhone:	(change:Endo<MeetingPhoneModel>) => void,
}>;

export const Meeting = ({ data, state, modifyGeneral, modifyPhone }:MeetingProps):JSX.Element => {
	const generalEditProps	= MeetingGeneralSchema.editorProps(data.general, modifyGeneral);
	const phoneEditProps	= MeetingPhoneSchema.editorProps(data.phone, modifyPhone);

	const languageCode	= useLanguageCode();
	const msg			= useMessages();

	return (
		<div class="floater-dialog inquiry">
			{ state === "error"	&&
				<Error/>
			}
			{ state === "ok"	&&
				<Success conversionType="conversion-meeting"/>
			}
			{ state === "form"	&&
				<div class="form">
					<Headline text1={msg.app.meeting1} text2={msg.app.meeting2}/>
					<div class="floater-dialog-intro">
						{msg.meeting.intro}
					</div>
					<div class="form-group">
						<RadioField
							label={msg.meeting.contactWay.label}
							missing={msg.form.fieldRequired}
							invalid={msg.form.fieldInvalid}
							choices={contactWays(msg)}
							{...generalEditProps.contactWay}
						/>
					</div>
					<div class="form-group">
						<TextField
							type="email"
							missing={msg.form.fieldRequired}
							invalid={msg.form.fieldInvalid}
							label={msg.meeting.email.label}
							placeholder={msg.meeting.email.placeholder}
							{...generalEditProps.email}
						/>
					</div>
					{ data.general.contactWay.value === "telephone" &&
						<div class="form-group">
							<TextField
								type="tel"
								missing={msg.form.fieldRequired}
								invalid={msg.form.fieldInvalid}
								label={msg.meeting.phone.label}
								placeholder={msg.meeting.phone.placeholder}
								{...phoneEditProps.phone}
							/>
						</div>
					}
					<div class="form-group">
						<AreaField
							label={msg.meeting.notes.label}
							missing={msg.form.fieldRequired}
							invalid={msg.form.fieldInvalid}
							placeholder={msg.meeting.notes.placeholder}
							{...generalEditProps.notes}
						/>
					</div>
					<div class="form-group">
						<CheckboxField
							label={ <span>{msg.form.privacyAgreement.pre}<a class="textlink" target="_blank" href={msg.form.privacyAgreement.link.href}>{msg.form.privacyAgreement.link.text}</a> {msg.form.privacyAgreement.post}</span> }
							missing={msg.form.fieldRequired}
							invalid={msg.form.fieldInvalid}
							{...generalEditProps.privacy}
						/>
					</div>
					<div class="button-bar button-bar--single">
						<button
							class="primary-button"
							type="button"
							onClick={() => actions.sendMeeting(languageCode)}
						>
							{msg.meeting.submitLabel}
						</button>
					</div>
				</div>
			}
		</div>
	);
};
