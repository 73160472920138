import { Branded }		from "ts-base/branded";
import { Validator }	from "ts-base/validation/validator";

// zero or positive
export type NaturalNumber	= Branded<number, "NaturalNumber">;
export namespace NaturalNumber	{
	export const brand	= Branded.brand<NaturalNumber>;

	export const zero	= brand(0);
	export const one	= brand(1);

	export const increment	= (it:NaturalNumber):NaturalNumber	=>
		brand(it + 1);

	export const add		= (a:NaturalNumber, b:NaturalNumber):NaturalNumber	=>
		brand(a + b);

	export const fromString	= (s:string):NaturalNumber|null	=>
		/^(0|[1-9][0-9]*)$/.test(s) ? brand(parseInt(s)) : null;

	// gets rid of negative zero, too!
	export const fromNumber	= (n:number):NaturalNumber|null	=>
		n >= 0 && Number.isSafeInteger(n) ? brand(n !== 0 ? n : 0) : null;

	export const validateString:Validator<string, NaturalNumber>	=
		Validator.fromNullResult(fromString, "expected a valid natural number");

	export const validateNumber:Validator<number, NaturalNumber>	=
		Validator.fromNullResult(fromNumber, "expected a valid natural number");
}

export type NonEmptyString	= Branded<string, "NonEmptyString">;
export namespace NonEmptyString	{
	export const brand	= Branded.brand<NonEmptyString>;

	export const fromString	= (s:string):NonEmptyString|null	=>
		s !== "" ? brand(s) : null;

	export const validateString:Validator<string, NonEmptyString>	=
		Validator.fromNullResult(fromString, "expected a non-empty string");
};

// non-empty
export type CountryId	= Branded<string, "CountryId">;
export namespace CountryId {
	export const brand	= Branded.brand<CountryId>;

	export const fromString	= (s:string):CountryId|null	=>
		s !== "" ? brand(s) : null;

	export const validateString:Validator<string, CountryId>	=
		Validator.fromNullResult(fromString, "expected a valid country id");
}

// see regex below
export type EmailAddress	= Branded<string, "EmailAddress">;
export namespace EmailAddress {
	export const brand	= Branded.brand<EmailAddress>;

	export const fromString	= (s:string):EmailAddress|null	=>
		/^[a-zA-Z0-9.!#$%&*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(s) ? brand(s) : null;

	export const validateString:Validator<string, EmailAddress>	=
		Validator.fromNullResult(fromString, "expected a valid email address");
};
