import { JSX }	from "preact";

import { Endo }				from "ts-base/endo";

import { PageInfo }			from "@geotoura/common/pageInfo";
import { useLanguageCode }	from "@geotoura/common/util/useLanguageCode";
import { AreaField }		from "@geotoura/common/form/AreaField";
import { CheckboxField }	from "@geotoura/common/form/CheckboxField";
import { TextField }		from "@geotoura/common/form/TextField";

import { useMessages }	from "@geotoura/floater/useMessages";
import * as actions		from "@geotoura/floater/actions";
import { InquirySchema, InquiryModel, FormState }	from "@geotoura/floater/model";

import { Error }		from "@geotoura/floater/Error";
import { Success }		from "@geotoura/floater/Success";
import { Headline }		from "@geotoura/floater/Headline";

export type InquiryProps	= Readonly<{
	data:				InquiryModel,
	state:				FormState,
	routeName:			string|null,
	pageInfo:			PageInfo,
	modifyInquiryForm:	(change:Endo<InquiryModel>) => void,
}>;

export const Inquiry = ({ data, state, routeName, pageInfo, modifyInquiryForm }:InquiryProps):JSX.Element => {
	const editProps	= InquirySchema.editorProps(data, modifyInquiryForm);

	const languageCode	= useLanguageCode();
	const msg			= useMessages();

	return (
		<div class="floater-dialog inquiry">
			{ state === "error"	&& <Error/> }
			{ state === "ok"	&& <Success conversionType="conversion-inquiry"/> }
			{ state === "form"	&&
				<div class="form">
					<Headline text1={msg.app.inquiry1} text2={msg.app.inquiry2}/>
					<div class="floater-dialog-intro">
						<p>{
							(pageInfo.type === "route" || pageInfo.type === "region") && routeName !== null
							? `${msg.inquiry.introRoute} "${routeName}". `
							: msg.inquiry.introGeneral
							}
						</p>
					</div>
					<div class="form">
						<div class="form-group">
							<TextField
								type="email"
								missing={msg.form.fieldRequired}
								invalid={msg.form.fieldInvalid}
								label={msg.inquiry.email.label}
								placeholder={msg.inquiry.email.placeholder}
								{...editProps.email}
							/>
						</div>
						<div class="form-group">
							<TextField
								type="text"
								missing={msg.form.fieldRequired}
								invalid={msg.form.fieldInvalid}
								label={msg.inquiry.date.label}
								placeholder={msg.inquiry.date.placeholder}
								{...editProps.date}
							/>
						</div>
						<div class="form-group">
							<AreaField
								label={msg.inquiry.notes.label}
								missing={msg.form.fieldRequired}
								invalid={msg.form.fieldInvalid}
								placeholder={msg.inquiry.notes.placeholder}
								{...editProps.notes}
							/>
						</div>
						<div class="form-group">
							<CheckboxField
								label={ <span>{msg.form.privacyAgreement.pre}<a class="textlink" target="_blank" href={msg.form.privacyAgreement.link.href}>{msg.form.privacyAgreement.link.text}</a> {msg.form.privacyAgreement.post}</span> }
								missing={msg.form.fieldRequired}
								invalid={msg.form.fieldInvalid}
								{...editProps.privacy}
							/>
						</div>
					</div>
					<div class="button-bar button-bar--single">
						<button
							class="primary-button"
							type="button"
							onClick={() => actions.sendInquiry(languageCode)}
						>
							{msg.inquiry.submitLabel}
						</button>
					</div>
				</div>
			}
		</div>
	);
};
