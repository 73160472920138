import { JSX }	from "preact";

import { Fn }			from "ts-base/fn";

import { FieldProblem }	from "@geotoura/common/form/field";

export type FieldErrorProps = {
	forId:		string,
	problem:	FieldProblem|null,
	missing:	string,
	invalid:	string,
};

export const FieldError	= ({ forId, problem, missing, invalid }:FieldErrorProps):JSX.Element	=>
	<div class="field-error" for={forId}>
		{ errorText(problem, missing, invalid) }
	</div>;

const errorText	= (problem:FieldProblem|null, missing:string, invalid:string):string	=>
		problem === null		? ""
	:	problem === "missing"	? missing
	:	problem === "invalid"	? invalid
	:	Fn.impossible(problem);
