import { Branded }		from "ts-base/branded";
import { Fn }			from "ts-base/fn";
import { Nullable }		from "ts-base/nullable";

import * as primitive	from "@geotoura/shared/primitive";

// TODO repr use AsUrlParameter - we have to merge our id concept with the representation stuff
export const identifierOrNull	= <B extends string>(it:string|null, brander:Fn<number, Branded<number, B>>):Branded<number, B>|null =>
	Nullable.then(naturalOrNull(it))(brander);

const naturalOrNull	= (it:string|null):number|null =>
	Nullable.then(it)(primitive.NaturalNumber.fromString);
