import { Fn }	from "ts-base/fn";

import {
	FieldSchema,
	FieldProblem,
	EditorSchema,
	EditorModel,
} from "@geotoura/common/form/field";

export type RadioSchema<T>	= EditorSchema<RadioState<T>>;

export type RadioModel<T>	= EditorModel<RadioState<T>>;

export type RadioState<T>	= T;

// all values are valid
export const FreeRadio	= <T>(initial:T):FieldSchema<RadioState<T>, T, T>	=>
	FieldSchema.of({
		initial:	initial,
		required:	false,
		restricted:	false,
		parse:		FieldProblem.noProblem,
		unparse:	Fn.identity,
	});
